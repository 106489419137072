import { ErrorBoundary } from '@appsignal/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import 'dayjs/locale/pt-br'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { MixpanelProvider } from 'react-mixpanel-browser'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import {
  GOOGLE_CLIENT_ID,
  HOTJAR_ID,
  HOTJAR_SNIPPET_VERSION,
  MIXPANEL_TOKEN,
} from './config/credentials'
import { MIXPANEL_CONFIG } from './config/mixpanel'
import { queryClient } from './config/query'
import AlertProvider from './context/AlertProvider'
import FullPageLoadingProvider from './context/FullPageLoadingProvider'
import ErrorFallback from './pages/ErrorFallback'
import Routes from './routes'
import { appSignal } from './services/appSignal'
import { persistor, store } from './store'
import theme from './styles/theme'

const App = () => {
  useEffect(() => {
    hotjar.initialize(HOTJAR_ID, HOTJAR_SNIPPET_VERSION)
  }, [])

  return (
    <ErrorBoundary
      instance={appSignal}
      tags={{ 'Stack Manager Version': import.meta.env.PACKAGE_VERSION || '?' }}
      fallback={() => <ErrorFallback />}
    >
      <MixpanelProvider config={MIXPANEL_CONFIG} token={MIXPANEL_TOKEN}>
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <QueryClientProvider client={queryClient}>
            <MuiThemeProvider theme={theme}>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="pt-br"
              >
                <CssBaseline />
                <Provider store={store}>
                  <PersistGate persistor={persistor}>
                    <AlertProvider>
                      <FullPageLoadingProvider>
                        <BrowserRouter>
                          <Routes />
                        </BrowserRouter>
                      </FullPageLoadingProvider>
                    </AlertProvider>
                  </PersistGate>
                </Provider>
              </LocalizationProvider>
            </MuiThemeProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </GoogleOAuthProvider>
      </MixpanelProvider>
    </ErrorBoundary>
  )
}

export default App
